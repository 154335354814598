import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import loginStyles from './login.module.css';
import LoginLogo from '../../../../assets/images/admin/logo.png';
import AdminDataServicve from '../../../../services/AdminDataServices';
import AuthAdmin from '../AdminAuth/adminAuth';
import { GlobalContext } from '../../../../context/GlobalState';
import { useCookies } from 'react-cookie';

const Login = () => {
	const [cookies] = useCookies(['adminAuthData']); // Define the cookie name
	const navigate = useNavigate();
	const api = new AdminDataServicve();
	const { saveAdminToken } = AuthAdmin();
	const { getCommonApiData } = useContext(GlobalContext);
	const [values, setValues] = React.useState({
		email: '',
		password: '',
	});
	const [validations, setValidations] = React.useState({
		email: '',
		password: '',
	});

	useEffect(() => {
		document.title = 'Reco Wizard::Admin Login';
		const email = cookies.adminAuthData;

		if (email) {
			// Process authData
			handleSubmit(email);
			
		}
	}, [cookies.adminAuthData]);

	const [showPassword, setShowPassword] = useState(false);
	const validateAll = () => {
		const { password, email } = values;
		const validations = { password: '', email: '' };
		let isValid = true;
		if (!email) {
			validations.email = 'Email address is empty.';
			isValid = false;
		}
		if (email && !/\S+@\S+\.\S+/.test(email)) {
			validations.email = 'This is not a valid Email format.';
			isValid = false;
		}
		// if (!password) {
		// 	validations.password = 'Password cannot be empty.';
		// 	isValid = false;
		// }
		// if ((password && password.length < 6) || password.length > 15) {
		// 	validations.password = 'Password must between 6 and 15 characters';
		// 	isValid = false;
		// }
		if (!isValid) {
			setValidations(validations);
		}
		return isValid;
	};
	const validateOne = (e) => {
		const { name } = e.target;
		const value = values[name];
		let message = '';
		if (!value) {
			message = `${name} is required.`;
		}
		if (
			value &&
			name === 'password' &&
			(value.length < 6 || value.length > 15)
		) {
			message = 'Password must between 6 and 15 characters.';
		}
		if (value && name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
			message = 'Email format must be as example@mail.com.';
		}
		setValidations({ ...validations, [name]: message });
	};
	const handleChange = (e) => {
		setValidations({ ...validations, email: '', password: '' });
		const { name, value } = e.target;
		setValues({ ...values, [name]: value });
	};
	const handleSubmit = async (email) => {
		// e.preventDefault();
		const isValid = validateAll();
		if (!isValid) {
			return false;
		}
		const result = await api
			.AdminvalidateLogin({
				user_email: values.email,
				user_password: btoa(values.password),
			})
			.then((res) => {
				saveAdminToken(res.data.accessToken);
				if (typeof getCommonApiData == 'function') {
					getCommonApiData(res.data.accessToken);
				}
				navigate('/cms/companies');
			})
			.catch((err) => {
				console.log(err);
				if (err?.response?.data?.error?.message) {
					setValidations({
						...validations,
						password: err.response.data.error.message,
					});
				}
			});
	
	
	};
	const { email, password } = values;
	const { email: emailVal, password: nameVal } = validations;
	return (
		<div className={loginStyles.login}>
			<div className="container">
				<div className="row" style={{ paddingTop: '130px' }}>
					{/* <div className="col-md-6 col-lg-6  " style={{ textAlign: 'center' }}>
						<div
							style={{
								width: '220px',
								height: '180px',
								margin: 'auto',
							}}
						>
							<img src={LoginLogo} alt="Logo" width="100%" height="100%" />
						</div>
					</div> */}
					<div className="col-md-6 col-lg-6">
						<div className="row">
							<div className="col-md-12 mb-4">
								<form
									className={loginStyles.login_form}
									onSubmit={handleSubmit}
								>
									<div className="mb-3">
										<label className="form-label">
											Email<span className="star">*</span>
										</label>
										<input
											type="email"
											name="email"
											value={email}
											onChange={handleChange}
											onBlur={validateOne}
											className="form-control"
											placeholder="Enter Admin Email"
										/>
										<div className="form-text text-danger">{emailVal}</div>
									</div>
									<div className="mb-3" style={{ position: 'relative' }}>
										<label className="form-label">
											Password<span className="star">*</span>
										</label>
										<input
											type={showPassword ? 'text' : 'password'}
											id="password-input"
											name="password"
											value={password}
											onChange={handleChange}
											onBlur={validateOne}
											placeholder="Enter Password"
											required
											className="form-control"
										/>
										<span
											className="toggle-password"
											style={{
												position: 'absolute',
												right: '0',
												bottom: validations.password != '' ? '30px' : '8px',
											}}
											onClick={() => setShowPassword(!showPassword)}
										>
											{showPassword ? (
												<i className="fa fa-eye-slash" aria-hidden="true"></i>
											) : (
												<i className="fa fa-eye"></i>
											)}
										</span>
										<div className="form-text text-danger">{nameVal}</div>
									</div>
									<div style={{ marginBottom: '35px' }}></div>

									<div className="d-grid gap-2 col-md-12 mx-auto">
										<button type="submit" className="primary_btn">
											Log In
										</button>
										{/* <Link to="/dashboard" className="primary_btn">Log In</Link> */}
									</div>
								</form>
							</div>
						</div>
					</div>
					{/* <div className="col-md-12 login_privacy mt-5 text-center">
						<p>
							By clicking "Log In" above, you acknowledge that you have read and
							understood
							<br />, and agreed to&nbsp;
							<Link to="/" style={{ textDecoration: 'underline' }}>
								Terms of Use
							</Link>{' '}
							and{' '}
							<Link to="/" style={{ textDecoration: 'underline' }}>
								Privacy Policy.
							</Link>
						</p>
					</div> */}
				</div>
			</div>
		</div>
	);
};
export default Login;
