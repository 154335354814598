import React from 'react';
import Logo from '../../assets/images/admin/Reap_logo.png';
import { useNavigate, useLocation } from 'react-router-dom';

const NotFound = () => {
	const navigate = useNavigate();
	const path = useLocation();

	let adminPath = path.pathname;
	let adminCurrentpath = adminPath.split('/')[1];

	function handleGoBack() {
		navigate(-1);
	}

	// if (adminCurrentpath == 'cms') {
	// 	navigate('cms');
	// }
	return (
		<div className="d-flex justify-content-center align-items-center vh-100">
			<div className="spinner-border" role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	);
};

export default NotFound;
