export const baseUrl = 'https://recowizardapi.sustent.in';
export const app_key = '726d589de3da065ef9cc1b3117e3e78d';
export const app_name = 'RECO-WIZARD';
export const app_version = 1;
//Frontend api path

export const apiPath = {
	//GET LANGUAGE
	tcLanguage: 'app/v1/getlanguage',
	// LOGIN TC USER
	tcLogin: 'app/v1/login',
	// DASHBOARD TC USER
	tcDashboard: 'app/v1/dashboard',
	// ADD NEW COMPANY TC USER
	tcAddcompany: 'app/v1/addCompany',
	//GET MASTER DATA
	tcMasterDataList: 'app/v1/getMasterListData',
	// GET EXECUTIVE SUMMERY
	getExecutiveSummary: 'app/v1/getExecutiveSummary',
	//POST EXECUTIVE SUMMERY
	addExecutiveSummary: 'app/v1/addexecutivesummary',
	//GET PROCESS FLOW
	getProcessFlow: 'app/v1/getprocess',
	//GET NOTIFICATIONS
	getNotifications: 'app/v1/getNotifications',
	//ADD PROCESS FLOW
	addProcessFlow: 'app/v1/addprocess',
	//GET COMPANY
	getcompanybyid: 'app/v1/getcompanybyid',
	//ADD IA VISIT DATE
	addiavisitdate: 'app/v1/addiavisitdate',
	//UPLOAD SIGNATURE
	uploadsignature: 'app/v1/uploadsignature',
	//ADD COMPANY CHECK POINT STATUS
	addCheckPointStatus: 'app/v1/companycheckpointstatus',
	//PDF DATA
	pdfdata: 'app/v1/pdfdata',
	//ADD DEVICE INFORMATION
	addDeviceInfo: 'app/v1/deviceinfo',
	//GET OBSERVATION BY COMPANY ID
	getObservationById: 'app/v1/getobservations',
	//ADD OBSERVATION BY COMPANY ID
	addObservationById: 'app/v1/addobservation',
	//DELETE OBSERVATION
	deleteObservation: 'app/v1/deleteObservations',
	//GET SCP IMPROVEMENT DATA
	getScpImprovements: 'app/v1/getscpimprovements',
	//GET GALLERY BY ID
	getGalleryByID: 'app/v1/getgalleryData',
	//UPDATE SCP DATA
	updateSCP: 'app/v1/updateScpImprovements',
	//GET ONSITE GALLERY
	OnsiteGallery: 'app/v1/getonsitegallerydata',
	//GET NOTES
	getNotes: 'app/v1/getnote',
	//ADD NOTES
	addNote: 'app/v1/addnote',
	//RESET PASSWORD
	resetPassword: 'app/v1/resetpassword',
	//GET TC DETAILS
	getTcDetails: 'app/v1/viewtcprofile',
	//SAVE GALLERY
	saveGallery: 'app/v1/savegalleryData',
	//DELETE GALLERY IMAGE
	deleteGallery: 'app/v1/deleteimage',
	//FORGOT PASSWORD
	forgotPassword: 'app/v1/forgotpassword',
	//READ ALL NOTIFICATION API
	readAllNotification: 'app/v1/readallnotification',
	//COMPANY CHECK POINT STATUS CHECK
	errorLog: 'app/v1/saveerrorlog',
};

//CMS api path
const cms = 'cms-api';

export const cmsApiPath = {
	// ADMIN LOGIN
	adminLogin: 'cms-api/login',
	//COMPANIES
	getAllCompanies: `${cms}/getcompany`,
	deactivateCompany: `${cms}/deactivatecompany`,
	duplicateCompany: `${cms}/duplicateCompany`,
	getCompanyById: `${cms}/getcompanybyid`,
	editCompany: `${cms}/editcompany`,
	getCountryAndRegionArray: `${cms}/getcountryandregionlist`,
	getCompanyByTcId: `${cms}/getcompanybytcid`,
	approveCompany: `${cms}/updatecheckpointstatus`,
	//STE AND TC
	addSTE: `${cms}/addstedata`,
	addTC: `${cms}/adduserdata`,
	getSteList: `${cms}/getstedata`,
	getTcList: `${cms}/getuserdata`,
	assignSte: `${cms}/assignste`,
	assignTC: `${cms}/assignetctocompany`,
	//resource , waste, processs
	getResourceData: `${cms}/getresourcemasterlist`,
	uploadResourceExcel: `${cms}/uploadresourceexcel`,
	getProcessData: `${cms}/getprocessmasterlist`,
	uploadProcessExcel: `${cms}/uploadprocessexcel`,
	getWasteData: `${cms}/getwastemasterlist`,
	uploadWasteExcel: `${cms}/uploadwasteexcel`,
	uploadObservationExcel: `${cms}/uploadObservationExcel`,
	getObservationMasterList: `${cms}/getobservationmasterlist`,
	getWorkAreaMasterList: `${cms}/getworkareamasterlist`,
	getSubThemeMasterList: `${cms}/getsubthememasterlist`,
	//update status
	updateResourceStatus: `${cms}/updateresourcestatus`,
	updateProcessStatus: `${cms}/updateprocessstatus`,
	updateWasteStatus: `${cms}/updatewastestatus`,
	updateObservationStatus: `${cms}/updateobservationstatus`,
	updateSubThemeStatus: `${cms}/updatesubthemestatus`,
	updateWorkAreaStatus: `${cms}/updateworkareastatus`,
	//custom to master
	resourceCustomToActive: `${cms}/iscustomresource`,
	processCustomToActive: `${cms}/iscustomprocess`,
	wasteCustomToActive: `${cms}/iscustomwaste`,

	updateObservationIsCustom: `${cms}/updateobservationiscustom`,
	updateSubthemeIsCustom: `${cms}/updatesubthemeiscustom`,
	updateWorkareaIsCustom: `${cms}/updateworkareaiscustom`,

	updateObservationRecommendation: `${cms}/updateObservationRecommendation`,
	//get language
	getLanguage: `${cms}/getlanguage`,
	getUserActivity: `${cms}/getuserlog`,
};
